import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const InvestCard = ({title, children, img, imgIsRight}) => {
  return (
    <Card imgIsRight={imgIsRight}>
      <Text>
        <Title>{title}</Title>
        <Content>{children}</Content>
      </Text>
      <div className="img">
        <GatsbyImage image={img?.gatsbyImageData} alt={img.title} />
      </div>
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  flex-direction: ${props => props.imgIsRight ? 'row' : 'row-reverse'};
  justify-content: center;
  align-items: center;
  margin: 0 0 60px 0;
  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
		}

  .img {
    width: 300px;
    margin:10px;
  }
`
const Text = styled.div`
  max-width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin:10px;
  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }

  
`
const Title = styled.h2`
  margin-bottom: 24px;
  font-size: 28px;
`
const Content = styled.div`
  font-size: 18px;

`

export default InvestCard