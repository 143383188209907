import React, { useState } from 'react'
import styled from 'styled-components'

import * as api from '../../api'

const RegisterYourInterest = () => {

  const handleSubmit = (e) => {
    e.preventDefault()
    api.registerInterest(inputState.firstName, inputState.lastName, inputState.email, inputState.phone, inputState.type, inputState.product)
      .then((res) => {
        if (res.status === 200) {
          setSubmissionResponse("Success");
        }
        else {
          setSubmissionResponse("Error");
        }
      })
      .catch((err) => {
        setSubmissionResponse("Error");
      })
  }

  const [inputState, setInputState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    type: '',
    product: '',
  })

  const [submissionResponse, setSubmissionResponse] = useState("")

  const handleChange = (input) => (e) => {
    setInputState(
      {
        ...inputState,
        [input]: e.target.value,
      }
    )
  }

  return (
    <MainWrapper>
      {
        submissionResponse === "Success"
        &&
        <div className='success'>
          Thank you for your interest. One of our investor services team members will be in contact with you shortly.
        </div>
      }
      {
        submissionResponse === "Error"
        &&
        <div className='error'>
          Sorry, some error occurred. Please try again in some time.
        </div>
      }
      {
        submissionResponse === ""
        &&
        <div className="content">
          <h2>Register Your Interest</h2>
        </div>
      }
      {
        submissionResponse === ""
        &&
        <Wrapper>
          <form onSubmit={handleSubmit}>
            <InputField>
              <Input>
                <label htmlFor='firstName'>First Name</label>
                <input id='firstName' type='text' required placeholder='John' value={inputState.firstName} onChange={handleChange('firstName')} />
              </Input>
              <Input>
                <label htmlFor='lastName'>Last Name</label>
                <input id='lastName' type='text' required placeholder='Smith' value={inputState.lastName} onChange={handleChange('lastName')} />
              </Input>
              <Input>
                <label htmlFor='email'>Email Address</label>
                <input id='email' type='email' required placeholder='johnsmith@example.com' value={inputState.email} onChange={handleChange('email')} />
              </Input>
              <Input>
                <label htmlFor='phone'>Phone Number</label>
                <input id='phone' type='tel' required placeholder='0201234567' value={inputState.phone} onChange={handleChange('phone')} />
              </Input>
              <Input>
                <label htmlFor='type'>What type of investor are you?</label>
                <select id='type' required value={inputState.type} onChange={handleChange('type')}>
                  <option value="Choose...">Choose...</option>
                  <option value="Retail">Retail</option>
                  <option value="Sophisticated">Sophisticated</option>
                  <option value="Advisor">Advisor</option>
                  <option value="Wholesale">Wholesale</option>
                </select>
              </Input>
              <Input>
                <label htmlFor='product'>What investment product are you interested in?</label>
                <select id='product' required value={inputState.product} onChange={handleChange('product')}>
                  <option value="Choose...">Choose...</option>
                  <option value="individual">individual</option>
                  <option value="Company">Company</option>
                  <option value="Trust">Trust</option>
                  <option value="SMSF">SMSF</option>
                </select>
              </Input>
            </InputField>
            <Button type="submit">Submit</Button>
          </form>
        </Wrapper>
      }
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  .success{
		padding: 20px;
    margin: 60px 0px 100px;
    text-align: center;
   background-color: #ddd;
	}
	.error{
		background-color: var(--base-red);
    padding: 20px;
    margin: 60px 0px 100px;
    text-align: center;
	}`

const Wrapper = styled.div`
	background: linear-gradient(155deg, rgb(38 75 101) 70%, rgb(46 84 111) 0);
	margin: 70px auto 120px;
	padding: 40px;
	width: 960px;

	border-radius: 20px;
  
	@media screen and (max-width: 991px) {
		width: 100%;
	};
  form {
    width: 100%;
  }
	.content {
		@media screen and (max-width: 991px) {
			width: 40%;
		};
		h2 {
			${'' /* text-align: center; */}
			margin: 0px 0px 16px 0px;
			color: rgb(255 255 255);
		};
	};
	.btnWrapper {
		display: flex;
		justify-content: flex-end;
		align-Items: center;
	};
`
const Input = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width:100%;
  label {
    margin-bottom: 8px;
    font-size: 17px;
    font-family: QuicksandBold;
    color: white;
  };
  input {
    width: 100%;
    height: 56px;
    padding: 8px 24px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
  }
  select {
    width: 100%;
    height: 56px;
    padding: 8px 24px;
    border-radius: 10px;
    border: none;
    option {
      font-size: 18px;
      color: var(--base-navy);
    }
  }
`
const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 18px 40px;
  height: 56px;
  font-size: 20px;
  font-family: PoppinsBold;
	border-radius: 30px;
  color: white;
  background: rgb(29 188 110);
  border: none;
  margin: 40px 0 0 0;
`
const InputField = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px 40px;
  margin-top: 20px;
	@media screen and (max-width: 768px) {
    display: flex;
		flex-direction: column;
		justify-content: center;
    align-items: center;
	}

`

export default RegisterYourInterest