import React from 'react'

import ContentContainer from '../UI/ContentContainer'
import TopicDescAndImg from '../UI/TopicDescAndImg'


const PersonalLoansUnitTrust = () => {

  const handleClick = () => document.getElementById('registerToInvest').scrollIntoView()

  return (
		<div id="learnMore">			
			<ContentContainer background='rgb(245 245 245)'>
				<TopicDescAndImg categoryQuery="SocietyOne Personal Loans Unit Trust" handleClick={handleClick}/>
			</ContentContainer>
		</div>
  )
}


export default PersonalLoansUnitTrust