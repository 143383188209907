import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import FaqsAccordion from '../UI/FaqsAccordion'
import HereToHelp from '../blocks/HereToHelp'

const InvestPageFaqs = () => {

	const queriedInvestPageFaqs = useStaticQuery(graphql`
		query InvestPageFaqs {
			contentfulComponentFaQsContainer (
				page: { eq: "Invest" }
			) {
				faq {
					question
					answer {
						childMarkdownRemark {
							html
						}
					}
				}
			}
		}
	`)

  return (
		<Faqs>
			<FaqsAccordion
				QandAs={queriedInvestPageFaqs.contentfulComponentFaQsContainer.faq}
			/>
			<HereToHelp />

		</Faqs>
  )
}

const Faqs = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 960px;
	margin: 0 auto;
	@media screen and (max-width: 991px) {
		width: 100%;
	};
`
export default InvestPageFaqs