import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"

import '../../styles/carousel.css'

const InvestCarousel = () => {

  return (
    <CarouselWrapper>
      <Carousel variant="dark" fade indicators={false}>
        <Carousel.Item>          
          <StaticImage src='../../assets/images/invest-img.jpeg' alt="invest" placeholder='none' />          
        </Carousel.Item>
        <Carousel.Item>          
          <StaticImage src='../../assets/images/invest-people-2.jpeg' alt="invest" placeholder='none' />          
        </Carousel.Item>
        <Carousel.Item>          
          <StaticImage src='../../assets/images/invest-people-3.jpeg' alt="invest" placeholder='none' />          
        </Carousel.Item>
      </Carousel>	
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled.div`
  .carousel-control-prev {
    display: none;
  }
  .carousel-control-next {
    display: none;
  }
`
export default InvestCarousel