import React from 'react'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import PersonalLoansUnitTrust from '../components/ForInvestPage/PersonalLoansUnitTrust'
import ProvenAssetClass from '../components/ForInvestPage/ProvenAssetClass'
import InvestInPeople from '../components/ForInvestPage/InvestInPeople'

const RootIndex = ({ path }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "Invest In The Personal Unit Trust",
    "datePublished": "2022-04-05",
  }
  return (
    <Layout>
      <Seo 
        title="Invest In The Personal Unit Trust"
        description="Our Personal Loans Unit Trust gives wholesale investors the opportunity to earn a target return ) of 4-7% p.a."
        path={path}
        structuredData={structuredData}
      />
      <InvestInPeople />
      <PersonalLoansUnitTrust />
      <ProvenAssetClass />
    </Layout>

  )
}


export default RootIndex
